import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import axios from "axios";
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from "dayjs/plugin/relativeTime"
import customParseFormat from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"

dayjs.locale('zh-cn')
dayjs.extend(relativeTime)
dayjs.extend(customParseFormat)
dayjs.extend(utc)

const app = createApp(App);

app.config.globalProperties.$filters = {
    dayjs(dataStr: any, pattern = 'YYYY-MM-DD HH:mm:ss') {
        return dayjs(dataStr).format(pattern)
    },
    fromNow(dataStr: any, pattern = 'YYYY-MM-DD HH:mm:ss') {
        return dayjs(dataStr).format(pattern)
    }
}

axios.defaults.baseURL = 'https://opmapi.yuzhangmen.cn/opm/'
// axios.defaults.baseURL = 'http://172.16.0.100:8088/opm/'
axios.interceptors.response.use(res => {
    return res.data
})
app.config.globalProperties.$axios = axios
app.use(IonicVue).use(router);
router.isReady().then(() => {
    app.mount('#app');
});