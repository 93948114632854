import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/talk/:id',
    component: () => import('../views/TalkDetail.vue')
  },
  {
    path: '/news/:id',
    component: () => import('../views/News.vue')
  },
  {
    path: '/rate',
    component: () => import('../views/Rate.vue')
  },
  {
    path: '/calculate',
    component: () => import('../views/Calculate.vue')
  },
  {
    path: '/project/:id',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/house/:id',
    component: () => import('../views/House.vue')
  },
  {
    path: '/rental/house/:id',
    component: () => import('../views/HouseRental.vue')
  },
  {
    path: '/about-au',
    component: () => import('../views/AboutAu.vue')
  },
  {
    path: '/about-au-detail/:index',
    component: () => import('../views/AboutAuDetail.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
